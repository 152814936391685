import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Logo from "../components/logo"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background-desktop-art.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <Layout>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#040e18`}
            data-pagename='art-section'
          >
            <Navigation sectionname="art-page" />
            <main className="main-container"></main>
            <footer className="art-page">
              <div className="main-body-content">
                <div style={{ float: "left", width: "60%" }}>
                  <h1 style={{ color: "white" }}>Art</h1>
                  <p>
                    While you sit back and savour your coffee, feast your eyes
                    on the artwork around you. Our walls are your walls, if
                    you'd like to display your own artwork in the store, please
                    drop us a line and we will let you know as soon as we have a
                    free spot.
                  </p>
                </div>
                <div style={{ float: "right", width: "30%" }}>
                  <Logo />
                </div>
              </div>
            </footer>
          </BackgroundImage>
        </Layout>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 100vh;
  color: white;
`

export default StyledBackgroundSection
